<template>
  <div>
    <AppMenu />
    <slot />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";

export default {
  name: "TextPage",
  components: {
    AppMenu,
  },
};
</script>
