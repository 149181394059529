<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-gray-800">
    <div
      class="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2"
    >
      <img
        class="w-full h-full object-cover"
        src="@/assets/contacts.jpg"
        alt=""
      />
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <h2
          class="text-base font-semibold uppercase tracking-wider text-gray-300"
        >
          Affidati ai professionisti
        </h2>
        <p
          class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl"
        >
          Contattaci
        </p>
        <p class="mt-3 text-lg text-gray-300">
          Il nostro team di professionisti lavora appositamente ed in maniera
          esclusiva per ogni singolo paziente, dedicandosi al suo benessere con
          sensibilità e professionalità.
        </p>
        <div class="mt-8">
          <div class="inline-flex rounded-md shadow ">
            <router-link
              to="contatti"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
            >
              Contattaci
              <!-- Heroicon name: external-link -->

              <svg
                class="-mr-1 ml-3 h-5 w-5 text-gray-400  hover:text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaContactSection",
};
</script>
