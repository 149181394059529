<template>
  <TextPage>
    <div class="relative py-16 overflow-hidden bg-white">
      <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          class="relative h-full mx-auto text-lg max-w-prose"
          aria-hidden="true"
        >
          <svg
            class="absolute transform translate-x-32 top-12 left-full"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            class="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            class="absolute transform translate-x-32 bottom-12 left-full"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mx-auto text-lg max-w-prose">
          <h1>
            <span
              class="block text-base font-semibold tracking-wide text-center text-blue-700 uppercase"
              >{{ title }}</span
            >
            <span
              class="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl"
              >Cos’è e come si manifesta?</span
            >
          </h1>
          <p class="mt-8 text-xl leading-8 text-gray-500">
            {{ subtitle1 }}
          </p>
        </div>
        <div class="mx-auto text-lg max-w-prose">
          <h1>
            <span
              class="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl"
              >Che esami è necessario fare?</span
            >
          </h1>
          <p class="mt-8 text-xl leading-8 text-gray-500">
            {{ subtitle2 }}
          </p>
        </div>

        <div class="mx-auto text-lg max-w-prose">
          <h1>
            <span
              class="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl"
              >Come si cura?</span
            >
          </h1>
          <p class="mt-8 text-xl leading-8 text-gray-500">
            {{ subtitle3 }}
          </p>
        </div>
      </div>
    </div>

    <div class="overflow-hidden bg-gray-50">
      <div class="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <svg
          class="absolute top-0 transform -translate-x-1/2 left-full -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="lg:col-span-1">
            <h2
              class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >
              Il percorso del paziente
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Dalla diagnosi alla cura
            </p>
          </div>
          <dl
            class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2"
          >
            <div>
              <div
                class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
              >
                <!-- Heroicon name: globe-alt -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="10" cy="10" r="7" />
                  <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
              </div>
              <div class="mt-5">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Visita ambulatoriale
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  {{ cta1 }}
                </dd>
              </div>
            </div>

            <div>
              <div
                class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
              >
                <!-- Heroicon name: scale -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1"
                  />
                  <path d="M8 15a6 6 0 1 0 12 0v-3" />
                  <path d="M11 3v2" />
                  <path d="M6 3v2" />
                  <circle cx="20" cy="10" r="2" />
                </svg>
              </div>
              <div class="mt-5">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Esami strumentali
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  {{ cta2 }}
                </dd>
              </div>
            </div>

            <div>
              <div
                class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
              >
                <!-- Heroicon name: lightning-bolt -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12h4l3 8l4 -16l3 8h4" />
                </svg>
              </div>
              <div class="mt-5">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Terapia
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  {{ cta3 }}
                </dd>
              </div>
            </div>

            <div>
              <div
                class="flex items-center justify-center w-12 h-12 text-white bg-blue-700 rounded-md"
              >
                <!-- Heroicon name: mail -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="7 7 12 12 7 17" />
                  <polyline points="13 7 18 12 13 17" />
                </svg>
              </div>
              <div class="mt-5">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Follow up
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  {{ cta4 }}
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <CtaContactSection />
    <FooterSection />
  </TextPage>
</template>

<script>
import FooterSection from "../components/FooterSection.vue";
import CtaContactSection from "../components/CtaContactSection.vue";
import TextPage from "../routes/TextPage.vue";

export default {
  name: "PatologiesList",
  components: {
    TextPage,
    FooterSection,
    CtaContactSection,
  },
  props: {
    title: String,
    subtitle1: String,
    subtitle2: String,
    subtitle3: String,
    cta1: String,
    cta2: String,
    cta3: String,
    cta4: String,
  },
};
</script>
