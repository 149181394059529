<template>
  <PatologiesPage
    title="Esofago di Barrett"
    subtitle1="L’esofago di Barrett è una condizione che si instaura come complicazione di una malattia da reflusso gastro-esofageo (MRGE) che dura da molti anni e che non è stata trattata efficacemente. In questa patologia la mucosa della parte distale dell’esofago assume l’aspetto di una mucosa di tipo intestinale. L’importanza di conoscere la presenza di questa condizione sta nel fatto che essa si associa ad un rischio aumentato di sviluppare il tumore dell’esofago. Tale rischio, seppur maggiore rispetto a chi non è affetto da esofago di Barrett, è tuttavia basso in senso assoluto. Fra i pazienti affetti da questa condizione ve ne sono alcuni che presentano un rischio di tumore ulteriormente aumentato e sono coloro che hanno un esofago di Barrett con displasia di basso grado o con lesioni macroscopiche ulcerate e/o nodulari. E’ essenziale che i pazienti affetti da esofago di Barrett entrino in un programma di sorveglianza endoscopica con l’esecuzione di EGDS (gastroscopie) a scadenze ben definite durante le quali venga effettuato un mappaggio bioptico per controllare la presenza o meno di displasia e l’eventuale progressione ad adenocarcinoma. La sintomatologia è simile a quella della MRGE, sebbene non di rado i pazienti si presentino asintomatici."
    subtitle2="Oltre allo studio morfologico con l’EGDS, è necessario effettuare uno studio funzionale con la manometria esofagea, per valutare la motilità dell’esofago e la competenza dello sfintere esofageo inferiore, ed un pH-impedenziometria esofagea 24 sotto terapia con inibitori di pompa protonica (PPI) per valutare se la terapia medica è efficace nel controllare il reflusso gastro-esofageo."
    subtitle3="I pazienti con esofago di Barrett devono assumere a vita farmaci PPI allo scopo di ridurre al massimo il danno indotto dal reflusso alla mucosa esofagea e quindi l’eventuale progressione ad adenocarcinoma. Una valida alternativa alla terapia medica è la chirurgia antireflusso, che diventa invece obbligatoria qualora la pH-impedenziometria esofagea 24h sotto terapia sia patologica. L’intervento che viene condotto per via mini-invasiva laparoscopica è una plastica antireflusso secondo Nissen ed è lo stesso che si effettua per la MRGE."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Barrett",
  components: {
    PatologiesPage,
  },
};
</script>
