<template>
  <div>
    <HeaderMenu />
    <MobileMenu />
  </div>
</template>

<script>
import HeaderMenu from "../components/HeaderMenu";
import MobileMenu from "../components/MobileMenu";

export default {
  name: "AppMenu",
  components: {
    HeaderMenu,
    MobileMenu,
  },
};
</script>